.consumer-wrapper {
  background: #F6F8FB;
  &__profile {
    display: flex;
    flex-wrap: wrap;
    // width: 777px;
    margin: 30px;
    margin-bottom: 0px;
    padding-bottom: 50px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .profile-body {
      background: white;
      border: 1px solid #EDF4FD;
      border-radius: 8px;
      padding: 32px;
      width: calc(50% - 32px);
      margin: 16px;
      margin-bottom: 22px;
      @media screen and (max-width: 767px) {
        width: 300px;
        margin: 8px auto;
      }
    }
  }
  .new-payment-container {
    padding: 0 90px;
    min-height: calc(100vh - 150px);
    padding-bottom: 20px !important;
    @media screen and (max-width: 1439px) {
      padding: 0 42px;
    }
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
}
