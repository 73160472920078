.failed-payment-info-container {
  margin-bottom: 28px;
  border: 1px solid #DF3B25;
  background: rgba(223, 59, 37, 0.1);
  border-radius: 16px;
  padding: 20px 32px;
  img {
    height: 18px;
    margin-right: 9px;
    margin-bottom: 2px;
  }
  .text-label {
    display: flex;
    font-size: 16px;
    font-weight: 700;
    color: #DF3B25;
    .inner-text {
      display: flex;
      flex-direction: column;
      .space {
        margin-top: 5px;
      }
    }
  }
}
