@import "./src/scss/variables";

.InputFromTo {
  margin-left: 24px;
  input {
    border: none;

    &:focus {
      outline: none;
    }
  }
}

.datepicker {
  position: absolute;
  margin-left: 15px;

  &--hidden {
    height: 40px;
    overflow-y: hidden;
  }

  .rdrDateDisplay {
    margin: 0;
    align-items: center;
    border: 1px solid $gray-lines;
    border-radius: 4px;
  }

  .rdrDateInput {
    box-shadow: none;

    &:first-child {
      &:after {
        content: "-";
        position: absolute;
        top: 9px;
        right: -10px;
        color: #000;
      }
    }

    input {
      height: 36px;
    }
  }

  .rdrDateDisplayWrapper {
    background: transparent;
  }

  .rdrMonthAndYearWrapper {
    margin-top: 10px;
    border-top: 1px solid $gray-lines;
    border-left: 1px solid $gray-lines;
    border-right: 1px solid $gray-lines;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .rdrMonths {
    border-left: 1px solid $gray-lines;
    border-right: 1px solid $gray-lines;
    border-bottom: 1px solid $gray-lines;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
