@import "../../scss/variables.scss";

.setup-plan {
  .MuiStepper-root {
    margin-top: 64px;
    padding: 0;

    .MuiStepLabel-label {
      color: $gray-medium;
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;

      &.MuiStepLabel-completed {
        color: $gray-medium;
      }

      &.MuiStepLabel-active {
        color: var(--customBlue);
      }
    }
  }

  .MuiStepIcon-root {
    color: $white;
    border: 2px solid $gray-medium;
    border-radius: 24px;

    text {
      fill: $gray-medium;
    }

    &.MuiStepIcon-active {
      color: $white;
      border: 2px solid var(--customBlue);
      border-radius: 24px;

      text {
        fill: var(--customBlue);
      }
    }

    &.MuiStepIcon-completed {
      color: $white;
      border: 2px solid $gray-medium;
      border-radius: 24px;

      path {
        stroke: $gray-medium;
      }
    }
  }

  .MuiStepConnector-lineHorizontal {
    display: none;
  }

  &__body {
    margin-top: 32px;

    @media screen and (max-width: 1439px) {
      margin: 32px auto 0;
    }

    @media screen and (max-width: 767px) {
      width: 350px;
      margin: 16px auto 0;

      .heading-2 {
        padding: 0 8px;
        margin-bottom: 8px;

        ~ .heading {
          padding: 0 8px;
        }
      }
    }
  }

  .setup-plan-container--repay {
    .setup-plan__footer {
      width: 350px;
      margin: 0 auto 24px;
    }
  }

  &__footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 24px;

    @media screen and (max-width: 1439px) {
      margin: 40px auto;
    }

    @media screen and (max-width: 767px) {
      width: 350px;
      margin: 20px auto;
    }

    + .heading.heading-body-bold {
      display: none;
      margin: 16px;
      text-align: center;

      @media screen and (max-width: 767px) {
        display: block;
      }

      a {
        white-space: pre;
      }
    }

    .error-message {
      position: absolute;
      left: 0;
      top: -28px;
      font-size: 11px;
      font-weight: bold;
      color: $alert-rich;
    }
  }

  .debt-start-finish-card {
    @media screen and (max-width: 1439px) {
      width: 416px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
