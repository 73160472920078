@import "./src/scss/variables";

.help-center-body {
  margin-top: 72px;
  min-height: calc(100vh - 215px);
  padding: 20px 80px;
  background: $custom-white-02;
  @media screen and (max-width: 1150px) {
    // min-height: calc(100vh - 330px);
    padding: 20px;
  }
  .title {
    font-size: 32px;
    color: #192636;
    font-weight: 800;
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .subtitle {
    color: #192636;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 790px;
    a {
      color: var(--customBlue);
      font-size: 16px;
      font-weight: 500;
    }
    span {
      font-weight: bold;
    }
  }
  .forms-container {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1150px) {
      flex-direction: column;
    }
    .block-container {
      cursor: pointer;
      margin-right: 16px;
      display: flex;
      border-radius: 16px;
      border: 1px solid var(--light-blue, #EDF4FD);
      background: var(--white, #FFF);
      padding-right: 15px;
      padding-bottom: 10px;
      width: 450px;
      @media screen and (max-width: 412px) {
        width: 100%;
        margin-top: 5px;
      }
      img {
        width: 76px;
        height: 76px;
        margin: 26px;
      }
      .text-block {
        margin-top: 15px;
        .block-title {
          color: #192636;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        .block-subtitle {
          color: #192636;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }
  .input-containers {
    width: 600px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      .name-container {
        display: flex;
        flex-direction: column;
        span {
          margin-top: 8px;
          font-size: 12px;
          font-weight: 700;
          color: #192636;
          margin-bottom: 5px;
          margin-left: 3px;
        }
        .name-input {
          height: 40px;
          width: 290px;
          @media screen and (max-width: 600px) {
            width: 100%;
          }
          border: 1px solid #EDF4FD;
          border-radius: 8px;
          padding-left: 5px;
        }
        .select-container {
          height: 40px;
          width: 290px;
          @media screen and (max-width: 600px) {
            width: 100%;
          }
          .css-1s2u09g-control {
            border: 1px solid #EDF4FD;
            border-radius: 8px;
          }
        }
      }
    }
    .select-checkbox-container {
      margin-top: 20px;
      span {
        font-size: 12px;
        font-weight: 700;
      }
      .checkbox-row {
        .checkbox-value {
          color: var(--customBlue);
        }
        span {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .other-input {
        resize: none;
        height: 100px;
        width: 100%;
        border: 1px solid #EDF4FD;
        border-radius: 8px;
        padding-left: 5px;
      }
    }
    .notes-container {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      span {
        font-size: 12px;
        font-weight: 700;
        color: #192636;
        margin-bottom: 5px;
        margin-left: 3px;
      }
      .notes-input {
        resize: none;
        height: 140px;
        border-radius: 16px;
        border: 1px solid var(--light-blue, #EDF4FD);
        background: var(--white, #FFF);
        padding: 15px;

        &.small {
          height: 80px;
        }
      }
    }
  }
  .terms-container {
    .checkbox-value {
      color: var(--customBlue);
    }
    .text-cont {
      font-size: 14px;
      font-weight: 500;
      a {
        color: var(--customBlue);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .send-btn {
    margin-top: 20px;
    width: 250px;
    justify-content: center;
    margin-bottom: 50px;
  }
}
