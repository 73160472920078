
.disputes-page-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  padding-top: 72px;
  .title {
    font-size: 32px;
    font-weight: 800;
    margin-top: 32px;
  }

}
