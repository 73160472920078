.review-plan {
  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .heading {
    margin-bottom: 16px;
  }

  .debt-custom-card {
    width: 416px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
