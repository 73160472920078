.success-screen {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  p {
    padding: 16px;
  }
}
