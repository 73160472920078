@import "../../scss/variables.scss";

.demand-letters {
  width: 100%;
  max-width: 1200px;
  margin: 50px auto;

  &__body {
    width: auto;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  &__form-wrapper {
    width: 600px;
    margin: 50px auto;

    h4 {
      margin-top: 40px;
      font-size: 16px;
      color: $alert-rich;
    }
  }
}
