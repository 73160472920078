@import "./src/scss/variables";

.forgot-password {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--customWhite01);

  &__container {
    width: 384px;
    margin: auto;
    position: relative;
    .logo {
      text-align: center;
      margin-bottom: 24px;
      img {
        height: 60px;
      }
    }
    .heading-1 {
      text-align: center;
      font-size: 28px;
      font-weight: 800;
      margin-bottom: 8px;
    }
    .subtitle {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: $light-black-02;
    }
  }

  h2 {
    margin-bottom: 24px;
  }

  .form-group {
    width: 100%;
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
    label {
      margin-bottom: 4px;
      color: $custom-gray;
    }
    .input-large {
      height: 42px;
    }
  }

  .button-primary {
    margin-top: 24px;
    width: 100%;
    height: 44px;
    font-size: 16px;
    justify-content: center;
  }

  .button-back {
    color: var(--customBlue);
  }

  &__error-message {
    font-weight: bold;
    font-size: 12px;
    color: $alert-rich;
  }

  .profile-password-checklist {
    ul {
      li {
        margin: 0px;
        svg {
          height: 13px;
        }
        span {
          font-size: 13px;
        }
      }
    }
  }
}
