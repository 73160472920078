@import "../../../../scss/variables.scss";

.bank-account-payment {
  position: relative;
  width: 416px;
  margin: 24px auto;

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 8px;
  }

  &__security-info {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    background-color: $gray-lines;
    color: $gray-dark;
    svg {
      margin-right: 4px;
    }
  }

  label {
    margin-bottom: 8px;
    color: $gray-medium;
  }

  .input {
    margin-bottom: 24px;
  }

  .nacha-container {
    position: absolute;
    width: 300px;
    right: -350px;
    top: 75px;

    @media screen and (max-width: 1140px) {
      position: static;
      width: 100%;
    }

    .nacha-text {
      height: 400px;
      overflow-y: auto;
      padding: 16px;
      border: 1px solid $gray-medium;
      border-radius: 4px;
      font-size: 14px;

      @media screen and (max-width: 1140px) {
        height: 200px;
      }
    }

    .bold {
      font-weight: bold;
      color: var(--customBlue);
    }

    .button {
      display: inline-block;
      margin: 16px 10px;
      font-size: 12px;
    }
  }

  .ach-preview {
    position: absolute;
    left: -350px;
    top: 100px;
    width: 300px;
    height: 150px;
    background-color: azure;
    padding: 10px;

    @media screen and (max-width: 1200px) {
      display: none;
    }

    &__content {
      width: 100%;
      height: 100%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 2px dashed rgba(0, 0, 0, 0.15);

      p {
        margin: 0;
        font-size: 10px;
      }

      .underlined {
        text-decoration: underline;
      }

      .content__header,
      .content__main,
      .content__footer {
        display: flex;
        justify-content: space-between;
      }

      .content__footer {
        align-items: flex-end;
      }

      .header__financial-institution {
        padding-right: 10px;
        width: 60%;
        font-size: 14px;
        color: $gray-dark;
      }

      .header__date {
        margin-left: auto;
        width: 40%;
      }

      .main__name-on-check {
        font-size: 14px;
        font-style: italic;
      }

      .main__sum {
        font-size: 14px;

        .bold {
          font-weight: bold;
        }

        .border {
          padding-right: 10px;
          border: 1px solid $gray-lines;
        }
      }

      .footer_account-number {
        margin-right: 10px;
      }
    }
  }
}
