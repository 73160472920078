@import "./src/scss/variables";

.profile-payment-method {
  .reff-number-container {
    margin-top: 20px;
    .label {
      font-size: 20px;
      font-weight: 700;
      color: $light-black-01;
    }
    .reference-number-select {
      border: 2px solid var(--customWhite01);
      border-radius: 8px;
      background: white;
      padding: 1px 3px;
      height: 40px;
    }
  }
  .master-card {
    background: white;
  }
  svg {
    border-radius: 4px;
  }
  .payment-method-title {
    font-size: 24px;
    font-weight: 800;
    color: $light-black-01;
  }
  .pay-pal-block {
    margin-top: 32px;
    .block-title {
      font-size: 20px;
      font-weight: 700;
      color: $light-black-01;
    }
    .pay-pal-container{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .add-new-pay-pal {
        margin-top: 16px;
        cursor: pointer;
        margin-right: 24px;
        min-width: 264px;
        max-width: 264px;
        padding: 60px;
        @media screen and (max-width: 767px) {
          min-width: 100%;
          max-width: 100%;
          height: 64px;
          padding: 20px;
          text-align: center;
        }
        height: 152px;
        background: white;
        border: 1px solid var(--customWhite01);
        border-radius: 16px;
        color: var(--customBlue);
        font-size: 16px;
        font-weight: 700;
        
      }
      .single-card {
        margin-top: 16px;
        margin-right: 24px;
        min-width: 264px;
        max-width: 264px;
        @media screen and (max-width: 767px) {
          min-width: 100%;
          max-width: 100%;
        }
        height: 152px;
        border: 1px solid $custom-white-02;
        background: var(--customWhite01);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top-block {
          display: flex;
          flex-direction: row;
          margin: 20px 10px;
          justify-content: space-between;
          .top-left{
            display: flex;
            flex-direction: row;
          }
          .default-btn-blue {
            width: 52px;
            height: 20px;
            background: var(--customBlue);
            border-radius: 4px;
            font-weight: 700;
            font-size: 10px;
            color: white;
            padding: 2px 8px;
            margin-top: 4px;
          }
          .default-btn-gray {
            width: 52px;
            height: 20px;
            cursor: pointer;
            border-radius: 4px;
            font-weight: 700;
            font-size: 10px;
            color: $custom-gray;
            background: white;
            padding: 2px 8px;
            margin-top: 4px;
          }
        }
        .card-number {
          margin: 0px 10px;
          font-weight: 700;
          font-size: 16px;
          color: $light-black-02;
        }
        .body-block {
          display: flex;
          justify-content: space-between;
          margin: 5px 10px 22px 10px;
          .card-desc {
            font-size: 12px;
            font-weight: 400;
            color: $light-black-02;
          }
          .card-left {
            display: flex;
            flex-direction: column;
          }
          .card-time {
            font-weight: 400;
            font-size: 12px;
            color: $light-black-02;
          }
        }
      }
    }
  }
  .cards-block {
    margin-top: 32px;
    .block-title {
      font-size: 20px;
      font-weight: 700;
      color: $light-black-01;
    }
    .cards-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .add-new-card {
        margin-top: 16px;
        cursor: pointer;
        margin-right: 24px;
        min-width: 280px;
        // max-width: 264px;
        text-align: center;
        padding-top: 60px;
        @media screen and (max-width: 767px) {
          min-width: 100%;
          max-width: 100%;
          height: 64px;
          padding: 20px;
          text-align: center;
        }
        height: 152px;
        background: white;
        border: 1px solid var(--customWhite01);
        border-radius: 16px;
        color: var(--customBlue);
        font-size: 16px;
        font-weight: 700;
        
      }
      .single-card {
        margin-top: 16px;
        margin-right: 24px;
        min-width: 280px;
        // max-width: 264px;
        @media screen and (max-width: 767px) {
          min-width: 100%;
          max-width: 100%;
        }
        height: 152px;
        border: 1px solid $custom-white-02;
        background: var(--customWhite01);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top-block {
          display: flex;
          flex-direction: row;
          margin: 20px 10px;
          justify-content: space-between;
          .top-left{
            display: flex;
            flex-direction: row;
          }
          .default-btn-blue {
            width: fit-content;
            height: 20px;
            background: var(--customBlue);
            border-radius: 4px;
            font-weight: 700;
            font-size: 10px;
            color: white;
            padding: 2px 8px;
            margin-top: 4px;
          }
          .default-btn-gray {
            width: fit-content;
            height: 20px;
            cursor: pointer;
            border-radius: 4px;
            font-weight: 700;
            font-size: 10px;
            color: $custom-gray;
            background: white;
            padding: 2px 8px;
            margin-top: 4px;
          }
        }
        .card-number {
          margin: 0px 10px;
          font-weight: 700;
          font-size: 16px;
          color: $light-black-02;
        }
        .body-block {
          display: flex;
          justify-content: space-between;
          margin: 5px 10px 22px 10px;
          .card-desc {
            font-size: 12px;
            font-weight: 400;
            color: $light-black-02;
          }
          .card-left {
            display: flex;
            flex-direction: column;
          }
          .card-time {
            font-weight: 400;
            font-size: 12px;
            color: $light-black-02;
          }
        }
      }
    }
  }
  .bank-account-block {
    margin-top: 32px;
    .block-title {
      font-size: 20px;
      font-weight: 700;
      color: $light-black-01;
    }
    .bank-account-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .add-new-bank-account {
        margin-top: 16px;
        cursor: pointer;
        margin-right: 24px;
        min-width: 280px;
        // max-width: 264px;
        @media screen and (max-width: 767px) {
          min-width: 100%;
          max-width: 100%;
          height: 64px;
          padding: 20px;
          text-align: center;
        }
        height: 152px;
        background: white;
        border: 1px solid var(--customWhite01);
        border-radius: 16px;
        color: var(--customBlue);
        font-size: 16px;
        font-weight: 700;
        padding-top: 60px;
        text-align: center;
        &.default-mail-wire {
          border: 2px solid var(--customBlue);
        }
      }
      .single-bank-account {
        margin-top: 16px;
        margin-right: 24px;
        min-width: 280px;
        // max-width: 264px;
        @media screen and (max-width: 767px) {
          min-width: 100%;
          max-width: 100%;
        }
        height: 152px;
        border: 1px solid $custom-white-02;
        background: var(--customWhite01);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top-block {
          display: flex;
          flex-direction: row;
          margin: 20px 10px 10px;
          justify-content: space-between;
          .top-left{
            display: flex;
            flex-direction: row;
          }
          .default-btn-blue {
            width: fit-content;
            height: 20px;
            background: var(--customBlue);
            border-radius: 4px;
            font-weight: 700;
            font-size: 10px;
            color: white;
            padding: 2px 8px;
            margin-top: 4px;
          }
          .default-btn-gray {
            width: fit-content;
            height: 20px;
            cursor: pointer;
            border-radius: 4px;
            font-weight: 700;
            font-size: 10px;
            color: $custom-gray;
            background: white;
            padding: 2px 8px;
            margin-top: 4px;
          }
        }
        .bank-name-block {
          font-size: 16px;
          font-weight: 400;
          margin: 0px 10px;
          color: $light-black-02;
        }
        .body-block {
          display: flex;
          justify-content: space-between;
          margin: 5px 10px 22px 10px;
          .block-container {
            .label {
              font-size: 12px;
              font-weight: 400;
              color: $custom-gray;
            }
            .value {
              color: $light-black-02;
            }
          }
        }
      }
    }
  }
  .delete-payment-icon {
    position: relative;
    margin-left: 10px;
    img {
      height: 18px;
      margin-bottom: 10px;
    }
  }
}
