@import "./src/scss/variables";

.single-agent {
  padding-top: 72px;

  .avatar-wrapper {
    h6 {
      color: $gray-medium;
    }

    .button {
      margin-top: 16px;
      color: $alert-rich;
    }
  }
}
