@import "./src/scss/variables";

.inventory-page {
  width: 500px;
  margin: 20px auto;

  &__form-block {
    margin-bottom: 16px;

    label {
      margin-bottom: 8px;
    }

    input[type="file"] {
      display: block;
    }

    select {
      display: block;
    }
  }

  .table-wrapper {
    width: 500px;
    overflow-x: auto;
  }
  table {
    tr {
      th,
      td {
        border: 1px solid $gray-lines;
        font-size: 11px;
        padding: 8px;

        &.errors {
          background-color: $alert-30;
        }
      }
    }
  }

  .buttons-wrapper {
    display: flex;

    button,
    a {
      margin-right: 10px;
    }

    .save {
      background-color: $established;

      &:hover {
        text-decoration: none;
        color: $white;
        opacity: 0.7;
      }
    }

    .save-valid {
      background-color: $alert-soft;

      &:hover {
        text-decoration: none;
        color: $white;
        opacity: 0.7;
      }
    }

    .download {
      background-color: $pending;

      &:hover {
        text-decoration: none;
        color: $white;
        opacity: 0.7;
      }
    }
  }
}
