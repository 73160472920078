@import "./src/scss/variables";

.cancel-plan-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    @media screen and (max-width: 767px) { 
      width: 100vw;
    }
  }
  .mod-header {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    span {
      font-size: 20px;
      font-weight: bold;
      color: $light-black-02;
    }
    .close-modal-btn {
      position: absolute;
      right: 30px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: $light-black-01;
    }
  }
  .mod-body {
    padding: 20px;
    color: #313131;
  }

  .mod-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 20px 26px 20px;
    button {
      border-radius: 8px;
      font-size: 16px;
    }
    .cancel-btn {
      border: 1px solid $custom-white-01;
      background: #FFFFFF;
      color: var(--customBlue);
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .confirm-btn {
      background: #DF3B25;
      color: white;

      @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: center;
        font-size: 16px;
      }
    }
    
  }
}
