@import "./src/scss/variables";

.change-payment-success {
  position: fixed;
  width: 600px;
  height: 56px;
  bottom: 10%;
  left: calc(50% - 270px);
  @media screen and (max-width: 767px) {
    width: 400px;
    left: calc(50% - 208px);
  }
  .modal-content {
    border: none;
    border-radius: 8px;
    .modal-body {
      background: var(--customWhite01);
      border-radius: 8px;
      .success-paym-container {
        display: flex;
        flex-direction: row;
        .success-icon {
          height: 24px;
          margin-right: 16px;
        }
      }
    }
  }
}
