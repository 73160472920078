@import "./src/scss/variables";

.single-consumer {
  padding-top: 72px;

  &__footer {
    @media screen and (max-width: 767px) {
      padding: 0 8px;
    }

    .heading {
      @media screen and (max-width: 767px) {
        text-align: center;
      }

      a {
        white-space: pre;
      }
    }
  }
}
