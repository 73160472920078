.info-tooltip {
  padding: 0;

  &.MuiButton-text {
    padding: 0;
  }

  &.MuiButton-root {
    min-width: 16px;
  }

  button {
    padding: 0;
  }

  .MuiTooltip-arrow {
    background-color: #fff;
    color: red;
  }
}
