@import "../../../../scss/variables.scss";

.payment-date {
  .heading {
    margin-bottom: 16px;

    .input {
      width: 42px;
      display: inline-block;
      margin: 0 11px;
      padding: 0 11px;

      &:focus {
        border-width: 1px;
      }
    }
  }

  > .heading.heading-caption-bold {
    display: flex;
    align-items: center;
    color: $established;
    cursor: pointer;

    input {
      margin-right: 8px;
    }
  }

  &__message {
    display: flex;
    position: relative;
    padding-left: 22px;

    .heading {
      color: $gray-medium;
    }

    svg {
      position: absolute;
      left: 0;
      top: 2px;
    }
  }

  .warning-text {
    display: flex;
    color: $alert-rich;
    font-size: 12px;
    margin-top: 12px;
    cursor: pointer;

    input {
      margin-right: 8px;
    }
  }
}
