@import "../../../../scss/variables.scss";

.payment-method {
  @media screen and (max-width: 767px) {
    padding: 8px;
  }

  .heading-2 {
    margin-bottom: 16px;
  }

  .button {
    display: flex;
    justify-content: center;
    width: 416px;
    height: 56px;
    padding: 0;
    margin-top: 16px;
    border: 2px solid $gray-lines;
    border-radius: 8px;

    @media screen and (max-width: 1439px) {
      width: 100%;
    }

    &.selected {
      background-color: $gray-light-02;
      border-color: var(--customBlue);
    }
  }

  .saved-cards {
    margin-top: 32px;

    .cards-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 16px;
    }

    .saved-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      padding: 16px;
      width: 192px;
      height: 128px;
      margin-bottom: 32px;
      border-radius: 8px;
      border: 1px solid $gray-lines;
      background-color: #fff;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      &:hover {
        padding: 15px;
        border: 2px solid var(--customBlue);
        background-color: $gray-light-02;
      }

      &.active {
        padding: 15px;
        border: 2px solid var(--customBlue);
        background-color: $gray-light-02;
      }

      h4 {
        text-align: left;
      }

      &__footer {
        width: 100%;
        display: flex;
        justify-content: space-between;

        div:last-child {
          margin-left: auto;
        }
      }

      &--new-card {
        .heading {
          margin-top: 36px;
          text-align: center;
        }
      }

      &--account {
        span.heading {
          color: $gray-medium;
        }
      }
    }
  }
}
