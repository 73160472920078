@import "./src/scss/variables";

.my-profile {
  .title {
    font-size: 32px;
    font-weight: 800;
    margin-top: 32px;
    padding: 0 64px;
  }
}
