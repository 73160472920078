@import "./src/scss/variables";

.notes-body {
  // &__new-note {
  // }
  padding: 32px !important;

  &__header-buttons {
    display: flex;

    .button:first-child {
      margin-right: 24px;
    }
  }

  .reference-number-select {
    padding: 5px;
  }

  .select-search-container {
    margin-left: 2px;
    .css-1s2u09g-control {
      border-radius: 8px;
      height: 40px;
      border: 2px solid var(--customWhite01);
    }
  }
  .date-picker-container {
    width: 350px;
  }
  .comm-count {
    padding: 10px 4px;
    font-size: 14px;
    .info-tooltip {
      svg {
        width: 20px;
        height: 20px;
        margin-right: 3px;
      }
      svg path {
        fill: red;
      }
    }

    &.red-color{
      color: red;
    }
  }

  .new-note {
    &__main {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }

    &__comment {
      width: 416px;

      textarea {
        margin-top: 8px;
        padding: 8px 12px;
        height: 90%;
        font-size: 14px;
        line-height: 24px;
        border: 1px solid $gray-lines;
        border-radius: 4px;

        &::placeholder {
          color: $gray-medium;
        }
      }
    }

    &__block {
      margin-bottom: 40px;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      button {
        margin: 0 8px 8px 0;
      }
    }
  }

  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 416px;
    padding: 20px 24px;
    background-color: #fff;
    box-shadow: 0px 9px 20px rgba(54, 64, 77, 0.14);
    border-radius: 8px;

    label.heading {
      margin-bottom: 8px;
      color: $gray-medium;
    }

    .MuiFormControlLabel-root {
      margin-bottom: 8px;
      font-size: 14px;

      .MuiButtonBase-root {
        padding: 0 10px;
      }

      .MuiRadio-colorPrimary {
        color: var(--customBlue);
      }
    }
  }
}
