@import "./src/scss/variables";

.debt-custom-card {
  position: relative;
  width: 416px;
  height: auto;
  overflow: hidden;
  padding: 24px 30px;
  border: 1px solid $gray-lines;
  border-radius: 8px;

  @media screen and (max-width: 1439px) {
    width: 336px;
    padding: 24px;
  }

  @media screen and (max-width: 767px) {
    width: 304px;
    margin-right: auto;
    margin-left: auto;
  }

  &:last-child {
    @media screen and (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  &--single-debt {
    height: 336px;
    background-color: $gray-light-02;
    border-color: $gray-light-02;

    @media screen and (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    svg {
      position: relative;
      top: 26px;
    }
  }

  &.debt-payment-card {
    width: 304px;
    padding: 16px;

    @media screen and (max-width: 1439px) {
      width: 244px;
    }

    @media screen and (max-width: 767px) {
      width: 272px;
    }

    .debt-payment-card__default-status {
      position: absolute;
      right: 16px;
      top: 10px;
      text-align: right;
      font-size: 11px;
      font-weight: 700;
      color: var(--customBlue);
    }

    .btn.btn-secondary {
      position: absolute;
      right: 16px;
      top: 10px;
      height: 20px;
      padding: 0 12px;
      font-size: 11px;
      background-color: transparent;
      color: var(--customBlue);
      border-color: var(--customBlue);
    }
  }

  &.custom-payment {
    .form-control {
      width: 90%;
    }

    .custom-payment__input-wrapper {
      position: relative;

      input {
        padding-left: 20px;
      }

      span {
        position: absolute;
        top: 7px;
        left: 10px;
      }
    }
  }

  .debt-payment-card__body {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--account {
      margin-top: 16px;
    }

    span {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
    }

    .expiration-date {
      margin-left: auto;
      margin-right: 40px;
    }

    .heading-caption-bold {
      color: $gray-medium;
      margin-bottom: 8px;
    }

    button {
      align-self: flex-end;
    }
  }

  &.payment-history {
    padding: 24px 0;
    overflow-x: auto;

    &.schedule {
      .debt-custom-card__table {
        width: 100%;
      }
    }

    .debt-custom-card__table {
      // width: 520px;

      th {
        padding-right: 8px;
        max-width: 93px;
      }
    }
  }

  h5 {
    margin: 0;
    color: $gray-medium;
    font-size: 12px;
    line-height: 16px;
  }

  &.show {
    height: auto;
    padding: 24px 30px 64px;
    background-color: $gray-light-02;
    border-color: $gray-light-02;

    @media screen and (max-width: 1439px) {
      padding: 24px 24px 64px;
    }
  }

  &__header {
    display: flex;

    > div {
      width: 50%;
    }

    p {
      color: $black;
      font-size: 17px;
      line-height: 32px;
      font-weight: bold;

      &.status {
        display: flex;
        align-items: center;
        margin-top: 8px;
        text-transform: uppercase;
        font-size: 11px;
        line-height: 16px;
        font-weight: bold;
        color: $black;

        .icon {
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 8px;
          border-radius: 8px;

          &.active {
            background-color: var(--customBlue);
          }

          &.payment {
            background-color: $payment;
          }

          &.established {
            background-color: $established;
          }

          &.paid-off {
            background-color: $paid-off;
          }

          &.recalled {
            background-color: $recalled;
          }
          &.on_hold {
            background-color: rgb(179, 179, 14);
          }
          &.delinquent {
            background-color: #656a70;
          }
        }
      }
    }
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid $gray-lines;

    p {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 24px;
      overflow: hidden;
      word-break: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
  }

  &__column {
    width: 47%;

    &:nth-child(odd) {
      margin-right: 3%;
    }

    &:nth-child(even) {
      margin-left: 3%;
    }

    &:nth-last-child(-n + 2) {
      p {
        margin-bottom: 0;
      }
    }
  }

  &__show-more {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    background-color: $gray-light-02;

    button {
      background-color: transparent;
      color: var(--customBlue);
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
      border: none;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  &__table {
    width: 100%;

    th {
      height: 24px;
      color: $gray-medium;
    }

    td {
      height: 32px;
      color: $black;
    }

    th,
    td {
      font-size: 11px;
      line-height: 16px;
      font-weight: bold;
    }

    th:nth-child(1),
    td:nth-child(1) {
      padding-left: 16px;
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: center;
    }

    th:nth-child(5),
    td:nth-child(5) {
      padding-right: 2px;
      text-align: right;
    }

    th:nth-child(6),
    td:nth-child(6) {
      padding-right: 2px;
      text-align: center;
    }

    tr:nth-child(even) {
      background-color: $gray-light-02;
    }

    tr.failed {
      background-color: $alert-30;
      opacity: 0.7;

      td {
        position: relative;
      }

      .info-tooltip {
        position: absolute;
        right: 36px;
        top: 8px;

        @media screen and (max-width: 1439px) {
          right: 24px;
        }

        @media screen and (max-width: 767px) {
          right: 20px;
        }

        svg path {
          fill: $alert-rich;
        }
      }
    }

    .money-block {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 68px;
    }

    .receipt  {
      button {
        margin: auto;
      }
      .svg {
        cursor: pointer;
  
        &:hover {
          path {
            fill: $alert-30;
          }
        }
      }
    }

    .nacha {
      button {
        margin-left: 9px;
        color: var(--customBlue);

        &:hover {
          color: $alert-30;
        }
      }

      svg {
        cursor: pointer;

        &:hover {
          path {
            fill: $alert-30;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;

    .btn {
      padding: 8px 64px;
      background-color: var(--customBlue);
      font-size: 14px;
      line-height: 40px;
      font-weight: bold;
      border-radius: 4px;

      &:hover {
        color: #fff;
      }
    }
    .edit-schedule-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .delete-schedule {
        background: red;
      }
    }
  }

  &--plan-selected {
    padding: 22px 30px;

    .debt-custom-card__block {
      padding-bottom: 0;
    }
    .debt-custom-card__footer {
      height: auto;
      align-items: center;

      .card-info {
        display: flex;
        align-items: center;
      }

      .heading {
        margin-right: 4px;
        margin-bottom: 0;
        color: $gray-medium;
      }

      .button {
        margin-top: 16px;
      }
    }
  }
}

.debt-start-finish-card {
  .debt-custom-card__block {
    padding: 0;
    border: none;
  }
}

.debts-custom-payment {
  width: 416px;
  margin-top: 0;
  padding: 24px 32px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid $gray-lines;

  @media screen and (max-width: 1439px) {
    width: 336px;
  }

  @media screen and (max-width: 767px) {
    width: 304px;
    padding: 15px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    color: $black;

    &:last-child {
      margin-bottom: 15px;
    }
  }

  &__input-wrapper {
    position: relative;

    span {
      position: absolute;
      left: 12px;
      top: 8px;
      color: $black;
    }
  }

  input {
    position: relative;
    width: 100%;
    height: 40px;
    padding-left: 30px;
    border: 1px solid $gray-lines;
    border-radius: 4px;

    &::placeholder {
      font-size: 14px;
      line-height: 24px;
      color: $gray-medium;
    }
  }

  &__list-wrapper {
    width: 100%;
    overflow-x: auto;

    @media screen and (max-width: 767px) {
      width: 272px;
    }
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
    width: 341px;

    @media screen and (max-width: 1439px) {
      width: 354px;
    }

    @media screen and (max-width: 767px) {
      width: 328px;
    }

    button {
      padding: 4px 9px;
    }

    li {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        button {
          padding: 0 15px;

          @media screen and (max-width: 767px) {
            padding: 0 10px;
          }
        }
      }
    }
  }

  &__next-btn {
    width: 172px;
    margin: 24px auto 0 !important;
    justify-content: center;
    font-size: 14px;
    color: $white;
    background-color: var(--customBlue);

    &:disabled {
      background-color: var(--customBlue);
    }
  }
}

.debt-success-payment {
  height: 112px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}
