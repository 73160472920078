@import "./variables.scss";

.heading {
  color: $more-black;
  margin: 0;

  &-1 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;

    @media screen and (max-width: 1280px) {
      font-size: 24px;
    }
  }

  &-2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    color: #313131;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 32px;
    }
  }

  &-3 {
    font-weight: bold;
    font-size: 17px;
    line-height: 32px;
  }

  &-body-medium {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  &-body-bold {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;

    &-underlined {
      text-decoration: underline;
    }
  }

  &-bold-caps {
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &-caption-bold {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }

  &-title {
    font-weight: bold;
    font-size: 82px;
    line-height: 96px;
  }

  &-subtitle {
    font-weight: normal;
    font-size: 32px;
    line-height: 56px;
  }
}
