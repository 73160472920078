@import "./src/scss/variables";

.profile-preview-mode {
  .preview-mode-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--customWhite01);
    padding-top: 7px;
    padding-bottom: 7px;
    &:last-child{
      border-bottom: none;
    }
    .preview-mode-label {
      width: 50%;
      font-size: 16px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
      font-weight: 700;
      color: $custom-gray;
    }
    .preview-mode-value {
      width: 50%;
      font-size: 16px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
      font-weight: 400;

      &.invalid-value {
        border: 2px solid #eb5050;
        background-color: rgba(255, 0, 0, 0.2);
        border-radius: 8px;
      }
    }
  }
}
.edit-mode-block {
  background: white;
  border: 1px solid var(--customWhite01);
  padding: 36px 32px;
  border-radius: 16px;
  @media screen and (max-width: 767px) {
    padding: 36px 20px;
  }
}

.add-contact-btn {
  color: var(--customBlue);
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
  margin-top: 10px;
}
