@import "./src/scss/variables";

.one-time-payment-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  @media screen and (max-width: 767px) { 
    width: 100vw;
  }
  .mod-header {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    max-width: 85%;
    span {
      font-size: 20px;
      font-weight: bold;
      color: $light-black-02;
    }
    .close-modal-btn {
      position: absolute;
      right: 30px;
      top: 40px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: $light-black-01;
    }
  }
  .mod-body {
    padding: 20px;
    span {
      position: relative;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
    .otp-input {
      position: relative;
      margin-top: 20px;
      p {
        font-size: 12px;
        color: $custom-gray;
        font-weight: 700;
        margin-bottom: 4px;
      }
      input {
        width: 95%;
        height: 40px;
        padding-left: 30px;
        border: 2px solid var(--customWhite01);
        border-radius: 8px;
      }
      span {
        position: absolute;
        left: 12px;
        top: 30px;
        color: $light-black-01;
      }
    }
    .otp-possible-payment-container {
      .otp-possible-payment-btn-list {
        display: flex;
        flex-direction: row;
        .otp-possible-payment-btn {
          margin-right: 8px;
          margin-top: 8px;
          border: 1px solid var(--customWhite01);
          border-radius: 8px;
          color: $light-black-02;
          font-size: 16px;
          font-weight: 400;
          @media screen and (max-width: 767px) {
            padding: 5px;
          }
        }
      }
    }
  }
  .mod-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 20px 26px 20px;
    button {
      border-radius: 8px;
    }
    .cancel-btn {
      border: 1px solid var(--customWhite01);
      background: #FFFFFF;
      color: var(--customBlue);
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .confirm-btn {
      @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
