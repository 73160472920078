@import "./src/scss/variables";

.tags-body {
  width: 70%;
  margin-left: 100px;

  &__main {
    margin-top: 24px;
  }

  &__block {
    margin-bottom: 40px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;

    .other-tag {
      position: relative;
      margin: 0 8px 8px 0;
      cursor: pointer;

      &:hover {
        .edit {
          display: block;
        }
      }
    }

    .edit {
      position: absolute;
      margin: 0;
      right: 0;
      top: 0;
      width: 58px;
      height: 100%;
      display: none;
      background-color: var(--customBlue);
      color: $white;
      border: none;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;

      svg {
        margin-right: 4px;

        path {
          stroke: $white;
        }
      }
    }
  }

  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 416px;
    padding: 20px 24px;
    background-color: #fff;
    box-shadow: 0px 9px 20px rgba(54, 64, 77, 0.14);
    border-radius: 8px;

    label.heading {
      margin-bottom: 8px;
      color: $gray-medium;
    }

    .MuiFormControlLabel-root {
      margin-bottom: 8px;
      font-size: 14px;

      .MuiButtonBase-root {
        padding: 0 10px;
      }

      .MuiRadio-colorPrimary {
        color: var(--customBlue);
      }
    }
  }
}
