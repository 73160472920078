@import "./src/scss/variables";

.consumer-debts-container-admin {
  display: flex;
  flex-direction: row;
  padding: 32px 90px;
  @media screen and (max-width: 1439px) {
    padding: 32px 42px;
  }
  background: $custom-white-02;
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .tabs-list-container {
    .tabs-list {
      background: #FFFFFF;
      border: 1px solid $custom-white-01;
      border-radius: 16px;
      margin-right: 32px;
      min-width: 290px;
      @media screen and (max-width: 1150px) {
        min-width: 234px;
      }
      @media screen and (max-width: 767px) {
        margin-right: 0px;
        margin-bottom: 20px;
      }
      .header {
        display: flex;
        flex-direction: column;
        padding: 20px;
        .total-balance-title {
          color: $custom-gray;
          font-size: 16px;
          line-height: 24px;
        }
        .total-balance-value {
          color: $light-black-02;
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
        }
      }
      .consumer-tabs-container {
        .consumer-tab-container {
          min-height: 68px;
          padding-top: 8px;
          @media screen and (max-width: 767px) {
            min-height: 50px;
            padding-top: 0px;
          }
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          text-transform: none;
          .tab-label-container {
            display: flex;
            flex-direction: column;
            text-align: left;
            padding-left: 8px;
            .tab-label-title {
              color: $custom-gray;
              font-size: 16px;
              line-height: 24px;
            }
            .value-container {
              display: flex;
              flex-direction: row;
              .tab-label-value {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
              }
              .status-container {
                text-transform: uppercase;
                font-size: 9px;
                font-weight: 700;
                border-radius: 4px;
                padding: 4px 4px;
                margin-left: 8px;
                background: $custom-white-02;
                color: $light-black-02;
                .status-icon {
                  display: inline-block;
                  width: 8px;
                  height: 8px;
                  margin-right: 4px;
                  border-radius: 8px;
                  &.active {
                    background-color: var(--customBlue);
                  }
                  &.delinquent {
                    background-color: #DF3B25;
                  }
                  &.paid_off {
                    background-color: #9CA4AF;
                  }
                  &.established {
                    background-color: #12B981;
                  }
                  &.recalled {
                    background-color: #192636;
                  }
                  &.on_hold {
                    background-color: rgb(179, 179, 14);
                  }
                }
              }
            }
          }
          .arrow-right {
            line-height: 50px;
          }
        }
        .Mui-selected {
          color: var(--customBlue);
          background: $custom-white-01;
          .status-container {
            background: white !important;
          }
          .tab-label-title {
            color: var(--customBlue) !important;
          }
        }
        .MuiTab-textColorInherit {
          opacity: 1;
        }
        .MuiButtonBase-root {
          max-width: 100%;
          &:last-child {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }
  
        .MuiTabs-indicator {
          display: none;
        }
      }
    }
  }
  .tab-body-container {
    width: 100%;
  }
}
