@import "./src/scss/variables";

.template-page-container {
  margin-top: 72px;
  width: 100%;
  .template-page-header {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--customWhite01);
    padding: 20px 64px;
    height: 115px;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
    .left-side {
      .back-btn {
        cursor: pointer;
        color: var(--customBlue);
      }
      .page-title {
        color: $light-black-02;
        font-size: 32px;
        font-weight: 800;
      }
    }
    .right-side {
      padding-top: 16px;
    }
  }
  .template-page-body {
    background: #f8f9fc;
    padding: 20px 64px;
    min-height: calc(100vh - 72px - 115px);
    .template-list-container {
      display: flex;
      flex-direction: row;
      .tabs-list {
        background: #FFFFFF;
        border: 1px solid var(--customWhite01);
        border-radius: 16px;
        margin-right: 32px;
        min-width: 290px;
        height: 100%;
        @media screen and (max-width: 1150px) {
          min-width: 234px;
        }
        @media screen and (max-width: 767px) {
          margin-right: 0px;
          margin-bottom: 20px;
        }
        .header {
          display: flex;
          flex-direction: column;
          padding: 20px;
          .total-balance-title {
            color: $custom-gray;
            font-size: 16px;
            line-height: 24px;
          }
          .total-balance-value {
            color: $light-black-02;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
          }
        }
        .consumer-tabs-container {
          .consumer-tab-container {
            min-height: 68px;
            padding-top: 8px;
            @media screen and (max-width: 767px) {
              min-height: 50px;
              padding-top: 0px;
            }
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            text-transform: none;
            .tab-label-container {
              display: flex;
              flex-direction: column;
              text-align: left;
              padding-left: 8px;
              .tab-label-title {
                color: $custom-gray;
                font-size: 16px;
                line-height: 24px;
              }
              .value-container {
                display: flex;
                flex-direction: row;
                .tab-label-value {
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 24px;
                }
                .pending-trigger {
                  font-size: 10px;
                  font-weight: 700;
                  background: white;
                  color: var(--customBlue);
                  border-radius: 4px;
                  padding: 4px 8px;
                  margin-left: 8px;
                }
                .failed-trigger {
                  font-size: 10px;
                  font-weight: 700;
                  background: rgba(223, 59, 37, 0.1);
                  color: #DF3B25;
                  border-radius: 4px;
                  padding: 4px 8px;
                  margin-left: 8px;
                }
              }
            }
            .arrow-right {
              line-height: 50px;
            }
          }
          .Mui-selected {
            color: var(--customBlue);
            background: var(--customWhite01);
          }
          .MuiTab-textColorInherit {
            opacity: 1;
          }
          .MuiButtonBase-root {
            max-width: 100%;
          }
          .MuiTab-root {
            min-height: 40px;
          }
          .MuiTabs-indicator {
            display: none;
          }
        }
      }
      .tab-body-container {
        width: 100%;
        .email-block-container {
          border: 1px solid var(--customWhite01);
          border-radius: 16px;
          background: #FFFFFF;
          display: flex;
          flex-direction: column;
          padding: 34px 28px;
          .input-container {
            display: flex;
            flex-direction: row;
            .label {
              font-size: 16px;
              font-weight: 400;
              color: $custom-gray;
              margin-right: 16px;
            }
            .email-input {
              border: 0px;
              width: 100%;
            }
            .reference-number-select {
              border: 0px;
              width: fit-content;
            }
          }
          .selector-container {
            display: flex;
            flex-direction: row;
            @media screen and (max-width: 1200px) {
              flex-direction: column;
            }
            .history-cont {
              margin-left: 16px;
              @media screen and (max-width: 1200px) {
                margin-left: 0px;
                margin-top: 8px;
              }
            }
          }
        }
        .template-preview-container {
          background: var(--customWhite01);
          border: 1px solid var(--customWhite01);
          border-radius: 16px;
          margin-top: 28px;
          .template-preview {
            background: #FFFFFF;
            border-radius: 16px;
            width: 600px;
            margin: auto;
            margin-top: 40px;
            margin-bottom: 40px;
            padding: 20px;
          }
        }
      }
    }
  }
}
