@import "./src/scss/variables";

.agents {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  padding-top: 72px;
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
    .title {
      font-size: 32px;
      font-weight: 800;
    } 
  }
}
