@import "./src/scss/variables";

.paymentHistory-table-container {
  margin-bottom: 20px;
  &.history {
    @media screen and (max-width: 1150px) {
      width: 60vw;
    }
    @media screen and (min-width: 768px) and (max-width: 900px) {
      width: 53vw;
    }
    @media screen and (max-width: 767px) {
      width: 77vw;
    }
  }
  .paymentHistory-table {
    width: 100%;
    &.history {
      @media screen and (max-width: 1150px) {
        display: block;
        overflow-x: scroll;
      }
    }
    th {
      height: 24px;
      color: $custom-gray;
      font-style: normal;
      font-weight: 400;
      @media screen and (max-width: 1150px) {
        font-size: 14px;
        font-weight: 700;
        min-width: 115px;
      }
    }
    td {
      height: 52px;
      color: $light-black-02;
      @media screen and (max-width: 1150px) {
        font-size: 14px;
      }
    }
    .payment-method {
      text-transform: capitalize;
    }
    th:nth-child(1),
    td:nth-child(1) {
      padding-left: 16px;
    }
    tr:nth-child(even) {
      background-color: $custom-white-02;
    }
    tr.failed {
      td {
        color: #DF3B25;
      }
    }
    .failed-info-tooltip {
      .info-tooltip {
        width: 155px;
        svg {
          width: 22px;
          height: 22px;
        }
        svg path {
          fill: #DF3B25;
        }
      }
    }
    .receipt-btn {
      box-shadow: 0px 8px 24px rgba(137, 166, 202, 0.05);
      border-radius: 8px;
      border: 1px solid var(--customWhite01);
      width: 155px;
      @media screen and (max-width: 767px) {
        width: 50px;
      }
      height: 44px;
      padding: 8px 15px;
      // margin: auto;
      cursor: pointer;
      img {
        width: 18px;
      }
      span {
        margin-left: 11px;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: var(--customBlue);
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
    .nacha {
      button {
        margin-left: 9px;
        color: var(--customBlue);

        &:hover {
          color: $alert-30;
        }
      }

      svg {
        cursor: pointer;

        &:hover {
          path {
            fill: $alert-30;
          }
        }
      }
    }
  }
}
