.default-container {
  padding-top: 72px;
  flex: 1 1;

  @media screen and (max-width: 767px) {
    padding-top: 56px;
  }
}

.homepage-header-container {
  width: 1440px;
  margin: 0 auto;
  padding: 0 176px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1280px) {
    width: 704px;
    padding: 24px 0;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    margin: 500px auto 0;
  }
}

.main-container {
  min-width: 1280px;
  min-height: calc(100vh - 72px);
  padding: 0 80px;
  background-color: #f6f8fb;
  @media screen and (max-width: 1439px) {
    padding: 0 32px;
  }
}

.setup-plan-container {
  width: 416px;
  margin: 0 auto 70px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  &--repay {
    width: 100%;

    iframe {
      width: 100%;
      height: 1220px; 
      @media screen and (max-width: 767px) {
        width: 350px;
      }
    }

    .MuiPaper-root {
      width: 350px;
      margin: 64px auto 0;
    }
  }
}

.tab-container {
  position: relative;
  max-width: 1320px;
  margin: 32px auto;
  display: flex;
  padding: 0 64px;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }

  &__column {

    &--medium {
      width: 640px;
      margin: 0 auto;
    }

    &:first-child {

      @media screen and (max-width: 767px) {
        position: relative;
        margin: auto;
      }

      .heading-2 {
        margin-bottom: 24px;
      }
    }
  }

  .tab-container__tabs {
    #vertical-tab-consumer,
    #vertical-tab-employer {
      position: relative;

      svg {
        position: absolute;
        right: 20px;
        top: 24px;

        path {
          fill: $alert-rich;
        }
      }
    }

    .MuiTabs-indicator {
      background-color: var(--customBlue);
    }

    .MuiTabs-flexContainer {
      @media screen and (max-width: 767px) {
        justify-content: center;
        border-bottom: 1px solid $gray-lines;
      }
    }

    .MuiTab-root {
      min-width: 192px;
      min-height: 56px;
      border-right: 1px solid $gray-lines;
      text-align: left;

      @media screen and (max-width: 1280px) {
        min-width: 152px;
      }

      @media screen and (max-width: 767px) {
        border-right: none;

        span {
          align-items: center;
        }
      }
    }

    .MuiTab-wrapper {
      align-items: flex-start;
      text-transform: capitalize;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: $gray-medium;
    }

    .Mui-selected {
      .MuiTab-wrapper {
        color: $black;
      }
    }
  }

  &__tabs {
    flex-grow: 1;
    display: flex;
    height: 224px;

    @media screen and (max-width: 767px) {
      display: none !important;
      height: auto;
    }

    &--mobile {
      display: none !important;

      @media screen and (max-width: 767px) {
        display: flex !important;
      }
    }
  }
}

.profile-page-container {
  padding-top: 72px;

  .tab-container__column {
    .avatar-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 288px;
      padding: 32px 0;
      border: 1px solid $gray-lines;

      .button-text {
        margin-top: 16px;
        color: $alert-rich;
      }
    }
  }
}

.success-container {
  width: 864px;
  margin: 56px auto;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
