@import "./src/scss/variables";

.edit-payment-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .mod-header {
    text-align: left;
    span {
      font-size: 20px;
      font-weight: bold;
    }
    .amount-values {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      margin-bottom: 5px;
      .title {
        color: #a1a8b0;
        font-size: 14px;
        &.remaining {
          margin-left: 20px;
        }
      }
      .value {
        color: black;
        font-size: 14px;
        margin-left: 5px;
      }
    }
    .close-modal-btn {
      position: absolute;
      right: 30px;
      top: 20px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: gray;
    }
  }
  .mod-body {
    .schedule-table {
      border: 2px solid var(--customWhite01);
      border-radius: 4px;
      thead {
        th {
          color: #a1a8b0;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .date {
          padding-left: 10px;
        }
        .status {
          text-align: center;
        }
      }
      tbody {
        display: block;
        min-height: 200px;
        max-height: 400px;
        overflow-y: scroll;
      }
      thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
      .schedule-row {
        border-top: 2px solid var(--customWhite01);
        .date {
          padding-left: 10px;
        }
        .amount {
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .status {
          text-align: center;
          .status-container {
            background: $custom-white-02;
            border-radius: 4px;
            width: fit-content;
            margin: auto;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            padding: 3px 6px;
          }
        }
      }
    }
  }

  .mod-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #a1a8b0;
    button {
      border-radius: 8px;
    }
    .cancel-btn {
      border: 1px solid var(--customWhite01);
      background: #FFFFFF;
      color: var(--customBlue);
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .confirm-btn {
      @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: center;
      }
    }
  } 
}
