@import "./src/scss/variables";

.profile-body {
  &.tab-container__column--medium {
    @media screen and (max-width: 1439px) {
      width: 520px;
      // margin: 0 0 0 auto;
    }

    @media screen and (max-width: 767px) {
      width: 272px;
      margin: 8px auto;
    }
  }

  .profile-form-title {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }

    &__buttons {
      display: flex;

      button {
        &:last-child {
          margin-left: 24px;
        }
      }
    }

    .heading {
      @media screen and (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
  }

  .profile-form__block {
    position: relative;

    .profile-title {
      margin-top: 21px;
      color: $light-black-01;
    }

    .profile-form__error {
      position: absolute;
      top: -24px;
      font-size: 12px;
      font-weight: bold;
      color: $alert-rich;
    }

    .profile-password-checklist {
      ul {
        li {
          margin: 0px;
          svg {
            height: 13px;
          }
          span {
            font-size: 13px;
          }
        }
      }
    }


    .profile-form-group {
      width: 46%;
      margin-bottom: 5px;

      @media screen and (max-width: 1190px) {
        width: 100%;
        margin-bottom: 5px;
      }

      &:nth-child(odd) {
        margin-right: 32px;

        @media screen and (max-width: 1190px) {
          margin-right: 0;
        }
      }

      // &--street {
      //   width: 416px;

      //   @media screen and (max-width: 1439px) {
      //     width: 336px;
      //   }

      //   @media screen and (max-width: 767px) {
      //     width: 100%;
      //   }
      // }

      // &--zip {
      //   width: 192px;

      //   @media screen and (max-width: 1439px) {
      //     width: 152px;
      //   }

      //   @media screen and (max-width: 767px) {
      //     width: 100%;
      //   }
      // }
    }

    .profile-form__container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;

      label {
        color: $gray-medium;
        margin-bottom: 8px;
        cursor: pointer;
      }

      .Mui-disabled {
        background-color: $gray-light-01;
      }

      .MuiInputBase-input {
        padding: 0;
      }

      .MuiFormControl-root {
        width: 100%;

        .Mui-disabled {
          background-color: transparent;
        }
      }

      .MuiInput-underline:before {
        border: none;
      }

      .button.button-secondary {
        width: 172px;
        padding: 8px 23px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .profile-form__error {
    color: $alert-rich;
    font-size: 12px;
    font-weight: bold;
  }
}

.profile-form_untie-google-account {
  @media screen and (max-width: 767px) {
    width: 300px;
    margin: 8px auto;
  }
  margin-left: 16px;
  .profile-title {
    margin-bottom: 10px;
  }
}

.third-container {
  width: 100%;
}