@import "./src/scss/variables";

.consumer-info {
  position: absolute;
  right: 62px;
  top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // padding-bottom: 24px;

  &__column-wrapper {
    display: flex;
    justify-content: flex-end;

    &:first-child {
      margin-bottom: 35px;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    .label {
      font-size: 16px;
      font-weight: 700;
      color: $custom-gray;
      margin-right: 8px;
      &.ssn {
        margin-left: 20px;
      }
    }
  }

  &__column {
    margin-left: 40px;

    &--middle {
      text-align: center;

      label {
        margin-bottom: 0;
        user-select: none;
      }

      .MuiButtonBase-root {
        padding: 0;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    h6 {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: $custom-gray;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $black;
      padding: 1px 1px 1px 0px;

      &.MuiIconButton-label {
        color: var(--customBlue);
      }
    }

    .PrivateSwitchBase-root-5 {
      padding: 0px;
      margin-right: 6px;
    }

    .MuiButtonBase-root.Mui-checked {
      + .MuiSwitch-track {
        background-color: var(--customBlue);
        opacity: 1;
      }
      .MuiSwitch-thumb {
        color: $white;
        border: 2px solid var(--customBlue);
      }
    }

    .MuiSwitch-track {
      background-color: $gray-lines;
      opacity: 1;
    }

    .MuiSwitch-thumb {
      color: $white;
      border: 2px solid $gray-lines;
      box-shadow: none;
    }
  }

  .opt-out__status {
    color: $custom-gray;
    &--disabled {
      color: $light-black-02;
    }
  }

  .commun-pref-btn {
    color: var(--customBlue);
    display: flex;
    flex-direction: row;
    border: 1px solid var(--customBlue);
    border-radius: 8px;
    padding: 9px 10px;
    cursor: pointer;
    .info-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 6px;
    }
  }


  .send-mail-btn {
    background: var(--customBlue);
    border-radius: 8px;
    height: 44px;
    padding: 10px 12px;
    color: white;
    margin-left: 40px;
    font-weight: 700;
    cursor: pointer;
    img {
      width: 22px;
      margin-right: 9px;
    }
  }

}
