@import "./variables.scss";

.other {
  &-tag {
    padding: 4px 8px;
    border: 1px solid $gray-lines;
    color: $gray-dark;
    font-size: 11px;
    font-weight: bold;
    line-height: 16px;
    border-radius: 4px;

    &:hover {
      color: var(--customBlue);
      border-color: var(--customBlue);
    }

    &--selected {
      background-color: var(--customBlue);
      border-color: var(--customBlue);
      color: $white;

      &:hover {
        color: var(--customBlue);
        background-color: $white;
        border-color: var(--customBlue);
      }
    }
  }

  &-checkbox {
    display: block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkmark {
      position: absolute;
      top: 2px;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 2px;
      background-color: $white;
      border: 2px solid var(--customBlue);
      box-sizing: border-box;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  &-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &-checkbox:hover input ~ .checkmark {
    background-color: $primary-10;
  }

  &-checkbox input:checked ~ .checkmark {
    background-color: var(--customBlue);
  }

  &-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  &-checkbox .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
