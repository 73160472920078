.app {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  min-height: 100vh;
}

.main {
  flex: 1 1;
  padding-top: 72px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#front-chat-container {
  z-index: 9 !important;
}

.cx-widget-status {
  display: none;
}
