@import "./src/scss/variables";

.remove-payment-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    width: 100%;
    @media screen and (max-width: 767px) { 
      width: 100vw;
    }
    padding: 35px;
    color: $light-black-02;
    .mod-header {
      text-align: left;
      span {
        font-size: 20px;
        font-weight: bold;
        text-transform: capitalize;
      }
      .close-modal-btn {
        position: absolute;
        right: 30px;
        padding: 0;
        background-color: transparent;
        border: none;
        color: $light-black-01;
      }
    }
    .mod-body {
      margin: 20px 0px 40px 0px;
      color: #313131;
    }
    .mod-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        border-radius: 8px;
      }
      .cancel-btn {
        border: 1px solid var(--customWhite01);
        background: #FFFFFF;
        color: var(--customBlue);
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      .confirm-btn {
        background-color: #DF3B25;
        @media screen and (max-width: 767px) {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
