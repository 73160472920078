@import "./src/scss/variables";

.consumer-overview-container {
  background: var(--customWhite01);
  border-radius: 16px;
  border: 1px solid var(--customWhite01);
  padding: 32px;
  @media screen and (max-width: 1150px) {
    display: none;
  }
  .title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: $light-black-01;
    margin-bottom: 12px;
  }
  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .block-container {
      display: flex;
      flex-direction: column;
      max-width: 145px;
      .label {
        color: $custom-gray;
        &.comm-count {
          color: red;
        }
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        max-width: 90px;
      }
      .value {
        color: $light-black-02;
        &.comm-count {
          color: red;
        }
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-transform: none;
        &.capital {
          text-transform: capitalize;
        }
      }
    }
    .see-details-btn {
      background: white;
      border-radius: 8px;
      box-shadow: 0px 8px 24px rgba(137, 166, 202, 0.05);
      border: 1px solid var(--customWhite01);
      color: var(--customBlue);
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      text-transform: none;
      padding: 0px 16px;
      height: 44px;
      @media screen and (max-width: 1150px) {
        padding: 10px;
      }
    }
  }
}

.mobile-accordion-container {
  display: none;
  @media screen and (max-width: 1150px) {
    display: block;
  }
  background-color: var(--customWhite01) !important;
  border-radius: 16px !important;
  .title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: $light-black-01;
    margin-bottom: 12px;
  }
  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 10px 10px 10px;
    .block-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 14px;
      .label {
        color: $custom-gray;
        &.comm-count {
          color: red;
        }
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
      .value {
        color: $light-black-02;
        &.comm-count {
          color: red;
        }
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-transform: none;
        &.capital {
          text-transform: capitalize;
        }
      }
    }
    .see-details-btn {
      background: white;
      border-radius: 8px;
      box-shadow: 0px 8px 24px rgba(137, 166, 202, 0.05);
      border: 1px solid var(--customWhite01);
      color: var(--customBlue);
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      text-transform: none;
      padding: 10px;
    }
  }
}
