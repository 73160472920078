@import "./src/scss/variables";

.consumer-footer {
  border-top: 1px solid var(--customWhite01);
  padding: 20px 80px 30px 80px;
  background: $custom-white-02;
  @media screen and (max-width: 1150px) {
    padding: 20px;
  }

  .top-blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .links-container {
      display: flex;
      flex-direction: row;
      .logo {
        @media screen and (max-width: 767px) {
          margin-left: 20px;
        }
      }
      .links {
        margin-left: 52px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        line-height: 35px;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        a {
          margin-right: 20px;
          font-weight: 400;
          font-size: 16px;
          line-height: 35px;
        }
      }
    }
    .security-images {
      @media screen and (max-width: 767px) {
        margin: auto;
      }
      display: flex;
      justify-content: space-between;
      img {
        height: 48px;
      }
      .entrust {
        margin-left: 30px;
      }
    }
  }

  .bottom-blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      span {
        text-align: center;
      }
    }
    .info {
      max-width: 480px;
      font-size: 12px;
      font-weight: 400;
      color: $custom-gray;
    }
    .copyright {
      font-size: 16px;
      font-weight: 400;
      color: $custom-gray;
    }
    .lang-cont {
      display: flex;
      .language-dropdown {
        margin-right: 10px;
        border: 0px;
        background: transparent;
        button {
          border: 0px;
        }
        .ReactFlagsSelect-module_selectOptions__3LNBJ {
          margin-top: -130px;
          padding: 2px 0;
        }
      }
    }
  }
}
