@import "./src/scss/variables";

.payments-history-container {
  margin-top: 28px;
  background:#FFFFFF;
  border-radius: 16px;
  border: 1px solid var(--customWhite01);
  padding: 32px;
  @media screen and (max-width: 1150px) {
    padding: 20px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .title {
      font-size: 20px;
      line-height: 46px;
      font-weight: 700;
      color: $light-black-01;
    }
    .btn-container {
      display: flex;
      flex-direction: row;
      .payment-history-tab-container {
        border-radius: 8px;
        border: 1px solid var(--customWhite01);
        cursor: pointer;
        display: flex;
        flex-direction: row;
        .payment-history-tab-title {
          padding: 10px 12px;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $custom-gray;
          &.active {
            color: var(--customBlue);
            font-weight: bold;
            background: var(--customWhite01);
          }
        }
      }
      .otp-btn {
        background: var(--customBlue);
        color: #FFFFFF;
        border-radius: 8px;
        margin-left: 16px;
        font-size: 16px;
        height: 44px;
        @media screen and (max-width: 1150px) {
          display: none;
        }
      }
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    .one-time-payment-btn-mobile {
      margin-top: 12px;
      display: none;
      width: 100%;
      color: #FFFFFF;
      background: var(--customBlue);
      font-weight: 700;
      padding: 10px 16px;
      border-radius: 8px;
      @media screen and (max-width: 1150px) {
        text-transform: none;
        display: block;
        font-size: 16px;
      }
    }
    .footer-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (max-width: 1150px) {
        flex-direction: column;
      }
      .info-block {
        font-size: 12px;
        font-weight: 400;
        text-align: right;
        color: $custom-gray;
        line-height: 30px;
        @media screen and (max-width: 1150px) {
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
}
