@import "./src/scss/variables";

.table-container {
  padding: 24px 0px;
  margin: 0 auto;
  width: 100%;
}

.table-controls {
  width: 100%;
  display: flex;

  input[type="search"] {
    width: 270px;
    height: 40px;
    font-size: 14px;
    line-height: 24px;
    padding-left: 36px;
    border-radius: 8px;

    &::placeholder {
      color: $gray-medium;
    }
  }

  .notes-search {
    width: 416px;
  }

  input[type="date"] {
    height: 40px;
    width: 304px;
    margin-left: 32px;
  }

  .search-container {
    position: relative;
    .form-control {
      border: 2px solid var(--customWhite01);
    }

    svg {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 13px;
      top: 14px;

      path {
        fill: $gray-medium;
      }
    }
  }

  label {
    margin: 0;
    color: $gray-medium;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }

  .btn-group {
    margin-left: 10px;
    border: 2px solid var(--customWhite01);
    border-radius: 8px;

    .btn {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      background-color: $white;
      color: $light-black-02;
      border: none;
      box-shadow: none;

      &:hover {
        transition: all 0.5s;
        // background-color: $gray-light-02;
        color: var(--customBlue);
        cursor: pointer;
      }

      &.active {
        background-color: var(--customWhite01);
        color: var(--customBlue);
        font-weight: 700;
      }
    }
  }

  .table-show-rows {
    display: flex;
    align-items: center;
    margin-left: auto;

    label {
      margin: 0 8px 0 0;
    }

    select {
      color: $black;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      border: 2px solid var(--customWhite01);
      border-radius: 8px;
    }
  }

  .table-show-pages {
    display: flex;
    align-items: center;
    margin-left: 8px;

    label {
      margin-right: 8px;
    }

    .btn {
      padding: 0;
      width: 40px;
      height: 40px;
      background-color: $white;
      opacity: 1;
      box-shadow: none;
      border-radius: 4px;
      color: $gray-medium;
      border-color: $gray-lines;

      &:disabled {
        background-color: $gray-light-01;
        opacity: 0.3;
      }

      &:last-child {
        margin-left: 8px;
      }
    }
  }

  .add-note {
    margin-left: 16px;
  }
}
