@import "./src/scss/variables";

.success {
  .heading {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;

      path {
        fill: $black;
      }
    }
  }

  &__header {
    @media screen and (max-width: 767px) {
      padding: 0 24px;
    }
  }

  &__sub-header {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }

    p {
      width: 526px;

      @media screen and (max-width: 767px) {
        width: auto;
        text-align: justify;
        margin-bottom: 8px;
      }
    }

    button {
      width: 192px;
      padding: 0 43px;

      &:first-child {
        margin-bottom: 8px;
      }
    }
  }

  &__body {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 24px;
    }
  }

  &__column {
    width: 416px;

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 416px;
    }

    .heading {
      margin-bottom: 8px;
    }

    .other-checkbox {
      margin-top: 24px;

      .heading {
        margin-left: 10px;
      }
    }

    .debt-custom-card {
      @media screen and (max-width: 1439px) {
        width: 416px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
