// MULTITENANCY COLORS
$custom-Blue: #639FF1;
$custom-white-01: #EDF4FD;
$custom-white-02: #F6F8FB;
$light-black-01: #313131;
$light-black-02: #192636;
$custom-gray: #9CA4AF;
$light-gray: #BCC3CC;


// SYSTEM
$white: #ffffff;
$gray-light-01: #f6f6f6;
$gray-light-02: #f6f8fb;
$gray-lines: #dadfe5;
$gray-small: #e2e2e2;
$gray-medium: #bcc3cc;
$gray-dark: #676b70;
$gray-large: #949494;
$gray-x-large: #636363;
$gray-xx-large: #242424;
$accent: #665ef1;
$black: #36404d;
$more-black: #192636;

// COLOR
$primary-soft: #7cb0f0;
$primary-30: #cadff9;
$primary-10: #edf4fd;
$alert-rich: #eb5050;
$alert-soft: #f07c7c;
$alert-30: #f9caca;
$alert-10: #fdeded;

// STATUS
$delinquent: #eb5050;
$payment: #d865eb;
$established: #33bb3a;
$paid-off: #bcc3cc;
$recalled: #656a70;
$pending: #edc540;

// HOMEPAGE
$homepage-header: rgba(101, 106, 112, 0.6);
$homepage-form: rgba(255, 255, 255, 0.8);
$homepage-primary-30: rgba(80, 149, 235, 0.3);
$homepage-primary-10: rgba(80, 149, 235, 0.1);

// BLUR
$blur-header: rgba(101, 106, 112, 0.8);
$blur-form: rgba(255, 255, 255, 0.8);
