@import "./src/scss/variables";

.admin-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--customWhite01);

  &__container {
    width: 384px;
    margin: auto;
    position: relative;
    .logo {
      text-align: center;
      margin-bottom: 24px;
      img {
        height: 60px;
      }
    }
    .subtitle {
      margin-top: 8px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: $light-black-02;
      span {
        font-weight: 700;
      }
    }
  }

  .heading-1 {
    text-align: center;
    font-size: 28px;
    font-weight: 800;
  }

  form {
    margin-top: 24px;

    label {
      margin-bottom: 4px;
      color: $custom-gray;
    }
    .input-large {
      height: 42px;
    }

    .form-group {
      margin-bottom: 0px;
      position: relative;

      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
      }

      svg {
        position: absolute;
        top: 33px;
        right: 13px;
      }

      &.password {
        svg path {
          fill: $alert-rich;
        }
      }
    }

    .button-link {
      margin-top: 8px;
      color: var(--customBlue);
      text-decoration: none;
    }

    .button-primary {
      margin: 24px auto 0;
      width: 100%;
      height: 44px;
      font-size: 16px;
      justify-content: center;
    }
  }

  button.heading-caption-bold,
  div.heading-caption-bold {
    margin-top: 8px;
  }

  .error {
    color: $alert-rich;

    // &--bottom {
      // position: absolute;
      // left: 0;
      // bottom: 72px;
    // }
  }

  .button-back {
    align-items: center;
    padding: 0;
    color: var(--customBlue);
  }

  .forgot-pass-container {
    display: flex;
    .forgot-pass-btn {
      margin: auto;
      margin-top: 24px;
    }
  }
}
