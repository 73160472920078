@import "./src/scss/variables";

.final-details-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    background-image: url('../../../../scss/gifs/17252-colorful-confetti.gif');
    @media screen and (max-width: 767px) { 
      width: 100vw;
    }
  }
  .mod-header {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    span {
      font-size: 20px;
      font-weight: bold;
      color: $light-black-02;
    }
    .close-modal-btn {
      position: absolute;
      right: 30px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: $light-black-02;
    }
  }

  .mod-body {
    padding: 20px;
    .title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      line-height: 32px;
      max-width: 360px;
      margin: auto;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      max-width: 360px;
      margin: auto;
      padding-top: 8px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .overview-text {
      font-size: 16px;
      font-weight: 700;
      color: $light-black-01;
      margin-bottom: 16px;
      margin-top: 20px;
    }
    .success-info-container {
      .info-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $custom-white-02;
        padding: 7px 0px;
        .label {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: $custom-gray;
        }
        .value {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: $light-black-02;
        }
      }
    }
  }

  .mod-footer {
    margin: 5px 0px 10px;
    .confirm-btn {
      margin: auto;
      font-size: 16px;
    }

    .download-receipt-btn {
      box-shadow: 0px 8px 24px rgba(137, 166, 202, 0.05);
      border-radius: 8px;
      border: 1px solid $custom-white-01;
      width: 203px;
      margin: auto;
      height: 44px;
      padding: 8px 15px;
      cursor: pointer;
      img {
        width: 18px;
      }
      span {
        margin-left: 11px;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: var(--customBlue);
      }
    }
  }
}
