.receipt-modal {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 9999;
}

.template__wrapper {
  height: 100vh;
  overflow-y: auto;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.template {
  background-color: #fff;
  width: 700px;
  height: 1300px;
  margin: 0 20px;
  margin-left: 40px;
  padding: 20px;
  font-size: 11px;

  @media screen and (max-width: 767px) {
    width: auto;
    margin: 0;
    height: 2220px;
  }
}

.template--nacha {
  height: 1000px;
}

.template__header {
  display: flex;
  justify-content: space-between;
  font-size: 11px;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }

  table {
    width: 55%;
    border: 1px solid lightgray;
    border-collapse: collapse;

    @media screen and (max-width: 767px) {
      width: auto;
      margin-top: 40px;
    }

    td {
      padding: 8px;
      border-bottom: 1px solid lightgray;
    }

    td:first-child {
      width: 150px;
    }

    td:last-child {
      border-left: 1px solid lightgray;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }
}

.template__header img {
  width: 35%;
}

.template__images {
  width: auto;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.template__images img {
  width: 200px;

  @media screen and (max-width: 767px) {
    width: 120px;
  }
}

.template__images img:nth-child(2) {
  width: 150px;
  margin-top: 20px;
}

.template__images p {
  margin-top: 20px;
  font-style: italic;
}

.template__main {
  margin-top: 32px;
  font-size: 11px;

  @media screen and (max-width: 767px) {
    margin-top: 18px;
  }
}

.main__top-info {
  margin-bottom: 32px;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.main__top-info p {
  margin-bottom: 12px;
}

.template__main p {
  text-align: justify;
  margin-bottom: 0;
  font-size: 11px;
}

.main__content {
  margin-top: 6px;

  p {
    &:first-child {
      font-size: 11px;
    }
    margin-bottom: 12px;
    font-size: 11px;
  }
}

.template__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.footer__block {
  width: 25%;
  margin-left: 0;
  justify-content: flex-start;
}

.footer__block h5 {
  margin-bottom: 5px;
  font-size: 11px;
}

.footer__block p {
  margin-bottom: 10px;
  font-size: 11px;

  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }
}

.footer__block:nth-child(2) {
  width: 50%;
  text-align: center;
}

.italic {
  font-style: italic;
}

.after {
  margin: 70px 0 44px;
  width: 135px;
  line-height: 24px;

  @media screen and (max-width: 767px) {
    margin: 10px 0 32px;
  }
}

.small-text {
  font-size: 9px;
}

.hide-button {
  position: absolute;
  right: 20px;
  top: 20px;
}

.bottom-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  border: 2px solid lightgray;
  padding: 4px;
}

.bottom-centered p {
  margin-bottom: 0;
  padding: 8px;
}

.conditional {
  font-size: 11px;
}

.receipt-modal__buttons {
  display: flex;

  .button:last-child {
    margin-left: 16px;
  }
}
