@import "./src/scss/variables";

.opt-select-amount-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    @media screen and (max-width: 767px) { 
      width: 100vw;
    }
  }
  .mod-header {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    span {
      font-size: 20px;
      font-weight: bold;
      color: $light-black-02;
    }
    .close-modal-btn {
      position: absolute;
      right: 30px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: $light-black-01;
    }
  }

  .otp-body-container {
    padding: 20px;
    @media screen and (max-width: 767px) {
      padding: 20px;
    }
    .main-block {
      margin-top: 8px;
      span {
        position: relative;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
      .otp-input {
        position: relative;
        margin-top: 20px;
        p {
          font-size: 12px;
          color: $custom-gray;
          font-weight: 700;
          margin-bottom: 4px;
        }
        input {
          width: 380px;
          @media screen and (max-width: 767px) {
            width: 270px;
          }
          height: 40px;
          padding-left: 30px;
          border: 2px solid $custom-white-01;
          border-radius: 8px;
        }
        span {
          position: absolute;
          left: 12px;
          top: 30px;
          color: $light-black-01;
        }
      }
      .otp-possible-payment-container {
        .otp-possible-payment-btn-list {
          display: flex;
          flex-direction: row;
          .otp-possible-payment-btn {
            margin-right: 8px;
            margin-top: 8px;
            border: 1px solid $custom-white-01;
            border-radius: 8px;
            color: $light-black-02;
            font-weight: 400;
            @media screen and (max-width: 767px) {
              padding: 5px;
            }
          }
        }
      }
    }
  }

  .mod-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 20px 26px 20px;
    button {
      border-radius: 8px;
      font-size: 16px;
    }
    .cancel-btn {
      border: 1px solid $custom-white-01;
      background: #FFFFFF;
      color: var(--customBlue);
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .confirm-btn {
      @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: center;
        font-size: 16px;
      }
    }
  }
}
