@import "./src/scss/variables";

.edit-payment-plan-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    @media screen and (max-width: 767px) { 
      width: 100vw;
    }
  }
  .mod-header {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    span {
      font-size: 20px;
      font-weight: bold;
      color: $light-black-02;
    }
    .close-modal-btn {
      position: absolute;
      right: 30px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: $light-black-01;
    }
  }

  .mod-body {
    padding: 20px;
    .edit-payment-step-container {
      border: 1px solid $custom-white-01;
      padding: 16px;
      border-radius: 8px;
      margin-bottom: 8px;
      margin-top: 8px;
      &.active {
        border: 1px solid var(--customBlue);
        background: $custom-white-02;
      }
      .verification-radio {
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-bottom: 0;
      }
      .verification-radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      .label {
        margin-left: 30px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $light-black-02;
        .card-number {
          margin-left: 8px;
          color: $light-black-02;
        }
        .card-time {
          color: $custom-gray;
          font-weight: 400;
        }
      }
      .checkmark {
        position: absolute;
        top: 2px;
        left: 0px;
        height: 20px;
        width: 20px;
        background-color: white;
        border-radius: 50%;
        border: 2px solid var(--customBlue);
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      .verification-radio input:checked ~ .checkmark:after {
        display: block;
      }
      .verification-radio .checkmark:after {
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--customBlue);
      }
    }

  }

  .mod-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 20px 26px 20px;
    button {
      border-radius: 8px;
      font-size: 16px;
    }
    .cancel-btn {
      border: 1px solid $custom-white-01;
      background: #FFFFFF;
      color: var(--customBlue);
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .confirm-container {
      display: flex;
      flex-direction: row;
      .remove-plan-btn {
        margin-right: 16px;
        border: 1px solid #DF3B25;
        color: #DF3B25;
        background: white;
      }
      .confirm-btn {
        @media screen and (max-width: 767px) {
          width: 100%;
          justify-content: center;
          font-size: 16px;
        }
      }
    }
  }

}
