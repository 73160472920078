@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap");

body {
  font-family: "Inter", sans-serif;
}

.no-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
}
