@import "./src/scss/variables";

.third-party-contact {
  margin-bottom: 50px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
      color: #313131;
    }
    .edit-btn-container {
      display: flex;
      flex-direction: row;
      button {
        border-radius: 8px;
      }
      .delete-icon {
        margin-right: 20px;
        width: 24px;
        height: 24px;
        margin-top: 7px;
        cursor: pointer;
      }
      .save-btn {
        margin-left: 20px;
      }
    }
  }
  .valid-error {
    font-size: 12px;
    font-weight: bold;
    color: #eb5050;
  }
  .edit-mode-cont {
    display: flex;
    flex-wrap: wrap;
    background: white;
    border: 1px solid var(--customWhite01);
    padding: 36px 32px;
    border-radius: 16px;
    margin-top: 8px;
    @media screen and (max-width: 767px) {
      padding: 36px 20px;
    }
  }

  .preview-mode-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--customWhite01);
    padding-top: 7px;
    padding-bottom: 7px;
    &:last-child{
      border-bottom: none;
    }
    .preview-mode-label {
      width: 50%;
      font-size: 16px;
      @media screen and (max-width: 767px) {
        width: 35%;
        font-size: 16px;
      }
      font-weight: 700;
      color: $custom-gray;
    }
    .preview-mode-value {
      width: 50%;
      font-size: 16px;
      color: $light-black-02;
      @media screen and (max-width: 767px) {
        width: 65%;
        font-size: 16px;
      }
      font-weight: 400;

      &.invalid-value {
        border: 2px solid #eb5050;
        background-color: rgba(255, 0, 0, 0.2);
        border-radius: 8px;
      }
    }
  }
}
