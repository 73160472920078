@import "./src/scss/variables";

.new-bank-account-modal {
    @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    @media screen and (max-width: 767px) { 
      width: 100vw;
    }
    width: 100%;
    padding: 35px;
  }
  .mod-header {
    text-align: left;
    span {
      font-size: 20px;
      font-weight: bold;
    }
    .close-modal-btn {
      position: absolute;
      right: 30px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: $light-black-01;
    }
  }
  .mod-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      border-radius: 8px;
    }
    .cancel-btn {
      border: 1px solid $custom-white-01;
      background: #FFFFFF;
      color: var(--customBlue);
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .confirm-btn {
      @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: center;
      }
    }
  }
  .ach-nach-con {
    border: 1px solid $custom-white-01;
    border-radius: 8px;
    padding: 8px;
    margin-top: 12px;
  }
}
