@import "./src/scss/variables";

.top-navigation {
  position: fixed;
  top: 0;
  width: 100%;
  backdrop-filter: blur(28px);
  z-index: 2;
  padding: 0;
  background:#FFFFFF;
  border-bottom: 1px solid var(--customWhite01);

  &__body {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 72px;
    width: 100%;
    margin: 0 auto;
    padding: 0 80px;

    @media screen and (max-width: 1439px) {
      padding: 0 32px;
    }
  }

  &--authenticated {
    .top-navigation__logo {
      svg path {
        fill: var(--customBlue);
      }
    }
  }

  &__logo {
    margin-top: 16px;
    // width: 100px;
    background-color: transparent;

    svg {
      width: 100px;

      path {
        fill: #ffffff;
      }
    }
  }

  &__links-list {
    display: flex;

    &--auth {
      @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        padding: 18px 28px;
        background-color: $gray-medium;
      }

      .MuiButtonBase-root {
        margin-left: 4px;
        &:hover {
          background-color: transparent;
        }

        svg path {
          fill: $alert-rich !important;
        }
      }
    }

    &--homepage {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__link-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 24px;
    background-color: $accent;
  }

  .nav {
    width: 100%;
    margin-top: 16px;
    margin-left: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;

    ul {

      li a {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
        color: $white;
      }

      li:last-child a {
        margin-right: 0;
      }

      &:last-child {
        li {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    a {
      color: $gray-xx-large;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
      cursor: pointer;

      &.top-navigation__link--auth {
        padding: 8px 12px;
        border-radius: 8px;
        color: $light-black-02;
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;

        &.active {
          color: var(--customBlue);
          background: var(--customWhite01);
          font-weight: 700;
        }

        svg {
          margin-right: 8px;
        }
      }
    }

    .btn {
      display: flex;
    }

    &__user-info {
      position: relative;
      margin-top: 8px;

      .btn {
        padding: 0 0 0 16px;
        img {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .navbar-toggler {
    position: absolute;
    right: 0;
    top: 14px;
  }

  .customer-name {
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    color: $black;

    @media screen and (max-width: 920px) {
      display: none;
    }
  }

  .mobile-menu-button {
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.mobile-menu {
  .MuiPaper-root {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .MuiMenu-list {
    padding: 0;

    svg {
      margin-right: 10px;
    }
  }

  .MuiPopover-paper {
    width: 225px;
    height: 568px;
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 0;
  }

  .MuiMenuItem-root {
    padding: 16px 24px;
    min-height: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .mobile-profile-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
    padding: 28px 0 20px;
    border-bottom: 1px solid $gray-lines;

    &:focus {
      outline: none;
    }

    .btn {
      padding: 0;
    }
  }

  .mobile-menu-item {
    color: $gray-medium;

    &.active {
      color: var(--customBlue);

      svg path {
        fill: var(--customBlue);
      }
    }
  }
}
