.cookies {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 80px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;

  @media screen and (max-width: 767px) {
    padding: 10px 20px;
    padding-bottom: 40px;
  }

  button {
    margin-left: 50px;
  }

  p {
    margin: 0;
    color: #fff;
    font-size: 14px;
  }

  a {
    text-decoration: underline;
    color: #fff;
    text-transform: lowercase;
  }
}
