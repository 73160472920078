@import "./src/scss/variables";

.payments-pagination {
  margin-top: 0px;

  .MuiPagination-root {
    margin-top: 0px;
    .MuiPaginationItem-page.Mui-selected {
      background-color: var(--customWhite01);
      color: var(--customBlue);
    }
  }
  .MuiPagination-ul {
    justify-content: left;
  }

  .pagination {
    justify-content: left;

    li:nth-child(-n + 2) {
      margin-right: 8px;

      a {
        border-radius: 4px;
      }
    }

    li:nth-last-child(-n + 2) {
      margin-left: 8px;

      a {
        border-radius: 4px;
      }
    }
  }
}
