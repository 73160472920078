button {
  &:focus {
    outline: none;
  }
}

.button {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  border: none;
  margin: 0;
  border-radius: 8px;

  img {
    width: 20px;
    margin-right: 5px;
  }

  &-primary {
    background: var(--customBlue);
    color: $white;

    &--medium {
      height: 40px;
      padding: 8px 16px;
      border-radius: 8px;
    }

    &--large {
      height: 56px;
      padding: 16px 24px;
      border-radius: 8px;
    }

    &:hover {
      background: var(--customBlue);
      opacity: 0.9;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &-secondary {
    background: transparent;
    color: var(--customBlue);
    border: 1px solid var(--customBlue);

    &--small {
      font-weight: 500;
      height: 32px;
      padding: 4px 12px;
      border-radius: 8px;
    }

    &--medium {
      height: 40px;
      padding: 8px 16px;
      border-radius: 8px;
    }

    &--large {
      height: 56px;
      padding: 16px 24px;
      border-radius: 8px;
    }

    &:hover {
      background: var(--customWhite01);
      opacity: 0.9;
    }

    &:disabled {
      background: $primary-10;
      opacity: 0.3;
    }

    .icon {
      margin-right: 8px;

      path {
        fill: var(--customBlue);
      }
    }
  }

  &-accept {
    background: $established;
    color: $white;
    border: 1px solid $established;

    &--medium {
      height: 40px;
      padding: 8px 24px;
      border-radius: 4px;
    }

    &--large {
      height: 56px;
      padding: 16px 24px;
      border-radius: 8px;
    }

    &:hover {
      background: transparent;
      color: $established;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &-text {
    &--simple {
      color: var(--customBlue);
      border: none;
      background: transparent;
    }

    &:hover {
      color: $primary-soft;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &-link {
    display: inline;
    color: var(--customBlue);
    border: none;
    background: transparent;
    text-decoration: underline;

    &:hover {
      color: $primary-soft;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &-transparent {
    background-color: transparent;
    border: none;
  }
}
