.modal {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    margin-top: 24px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
  }
}
