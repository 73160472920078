.user-debts {
  flex: 1 1;

  @media screen and (max-width: 767px) {
    padding: 0;
  }

  .tab-content {
    margin-top: 32px;

    @media screen and (max-width: 767px) {
      margin-top: 24px;
    }
  }
}
