@import "./src/scss/variables";

.change-payment-method {
  margin: 24px auto;
  width: 100%;

  .heading-2 {
    text-align: center;
  }

  &__body {
    width: 416px;
    margin: 24px auto;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &--repay {
      width: 100%;

      iframe {
        width: 100%;
        height: 1220px;
        @media screen and (max-width: 767px) {
          width: 350px;
        }
      }
    }

    .payment-button {
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      border-radius: 8px;
      border: 1px solid $gray-lines;
      background-color: transparent;
      font-size: 14px;
      line-height: 24px;
      color: $black;
      font-weight: bold;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: 416px;
    margin: 16px auto;

    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 16px;
    }
  }
}
