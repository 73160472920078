@import "./src/scss/variables";

.unsubscribe-body {
  margin-top: 72px;
  min-height: calc(100vh - 215px);
  padding: 20px 80px;
  background: $custom-white-02;
  @media screen and (max-width: 1150px) {
    // min-height: calc(100vh - 330px);
    padding: 20px;
  }
  .title {
    font-size: 32px;
    color: #192636;
    font-weight: 800;
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .subtitle {
    color: #192636;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 790px;
    span {
      font-weight: bold;
    }
  }

  .action-btn {
    margin-top: 20px;
    width: 250px;
    justify-content: center;
    margin-bottom: 50px;
  }
}
