@import "./variables.scss";

.input {
  display: block;
  width: 100%;
  background-color: $white;
  border: 2px solid var(--customWhite01);
  color: $black;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  &--invalid {
    border-color: $alert-rich;
    background-color: rgba(255, 0, 0, 0.2);
  }

  &-medium {
    padding: 0 12px;
    height: 40px;
    border-radius: 8px;

    &:focus {
      border: 2px solid $primary-30;
    }
  }

  &-date {
    display: flex;
    align-items: center;

    &.disabled {
      background-color: $gray-light-01;
    }
  }

  &-large {
    padding: 0 16px;
    height: 56px;
    border-radius: 8px;

    &:focus {
      border: 3px solid rgba(80, 149, 235, 0.3);
    }
  }

  &:hover {
    border: 1px solid var(--customBlue);
  }

  &:disabled {
    background-color: $gray-light-01;
  }

  &-has-error {
    border-color: $alert-rich;
  }
}
