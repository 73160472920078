@import "./src/scss/variables";

.history-body {
  &.main-container {
    margin: 0 auto;
  }

  .table-container {
    padding: 0px 0px 25px 0px;
  }

  .table-wrapper {
    width: 640px;
    margin-top: 32px;
  }

  .table-wide {
    margin-top: 32px;
    width: 100%;

    th:first-child {
      width: 200px;
    }
  }
}
