@import "./src/scss/variables";

.see-details-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    @media screen and (max-width: 767px) { 
      width: 100vw;
    }
  }
  .mod-header {
    text-align: left;
    margin-left: 15px;
    span {
      font-size: 18px;
      font-weight: bold;
    }
    .close-modal-btn {
      position: absolute;
      right: 30px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: $light-black-01;
    }
  }
  .mod-body {
    padding: 10px;
    .MuiAccordionSummary-content {
      justify-content: space-between;
      span {
        width: 50%;
      }
      .label {
        color: $custom-gray;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        user-select: text;
      }
      .value {
        color: $light-black-02;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        user-select: text;
      }
    }
    .MuiPaper-elevation1 {
      // box-shadow: none;
    }
    .MuiAccordionActions-root {
      justify-content: flex-start;
      padding: 0px 10px 10px 10px
    }
  }
}
