@import "./src/scss/variables";

.help-center-success {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 40px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .mod-body {
    .title {
      font-size: 28px;
      font-weight: 800;
      text-align: center;
      line-height: 32px;
      max-width: 384px;
      margin: auto;
      padding-top: 42px;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      max-width: 384px;
      margin: auto;
      padding-top: 12px;
    }
  }
  .mod-footer {
    margin-top: 40px;
    margin-bottom: 60px;
    .close-btn {
      margin: auto;
      width: 384px;
      height: 48px;
      @media screen and (max-width: 767px) {
        width: 90%;
      }
      justify-content: center;
    }
  }
}
